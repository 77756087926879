.plus-button-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.svg-shapes {
  background-color: #ffffff;
  background-image: url("https://cdn.glitch.com/2178dee2-bd74-4c6b-8722-eef5a9f62e3a%2Fpattern.svg?v=1605427776101");
  background-repeat: repeat;
}

.explore-card {
  width: 280px;
  height: 480px;
  background-size: cover;
  margin: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 4px 6px 16px -5px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 4px 6px 16px -5px rgba(0, 0, 0, 0.53);
  box-shadow: 4px 6px 16px -5px rgba(0, 0, 0, 0.53);
}

.explore-card-info{
  color: black;
  position: absolute;
  background-color: white;
  width: 100%;
  bottom: 0;
  right: 0;
  font-size: 14px;
  padding: 10px;
}