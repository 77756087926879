.story-view-header{
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0.75em;
    color: rgb(245, 245, 245);
    z-index: 10;
    pointer-events: none;
    font-family: 'Rubik', sans-serif;
    text-align:left;
}