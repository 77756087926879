  .timer-container {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: 1px solid yellow;
    background: white;
    width: 50px;
    height: 50px;
  }
  .interactive-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: black;
    overflow: hidden;
    animation-duration: 2s;
    pointer-events: auto;
    border: 0px solid red;
  }
  
  .storygraph-container {
    font-family: "Manrope";
    letter-spacing: 0.0625rem;
    box-sizing: content-box;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .ui-container {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    flex-direction: column;
  }
  
  .ui-container-top-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 125px;
    width: 50px;
    padding: 0.25em;
  }
    
  .play-button {
    width: 75px;
    height: 75px;
    background: url("../img/storygraph-play-button.png");
    background-size: cover;
    pointer-events: none;
  
    z-index: 3;
  }
  .play-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  
  .options-container {
    text-align: center;
    pointer-events: none;
    display: none;
    position: relative;
    flex: 0.33;
  }
  .options-container button,
  .options-container input {
    pointer-events: auto;
  }
  
  .sg-text-input{
    font-size:20px;
  }
  
  .btn {
    padding: 0.35em 0.5em;
    border: 0.125em solid rgba(255, 255, 255, 1);
    background-color: rgba(146, 146, 146, 0.45);
    color: rgba(255, 255, 255, 1);
    font-family: "Fira Sans Condensed", sans-serif;
    margin: 0.5em 0em;
    box-shadow: 0px 0px 12px -4px rgb(255, 255, 255);
    font-size: 1.25em;
  }
  .btn + .btn {
    margin-left: 0.75rem;
  }
  .btn:hover,
  .btn:active {
    transform: scale(1.25);
  }
  